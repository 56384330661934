var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"width":"150","block":"","color":"primary","small":"","to":"/main/driver/create"}},[_c('span',[_vm._v(_vm._s(_vm.$_strings.driver.CREATE_DRIVER))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('MasterDataCsvUpload',{attrs:{"importTypeProp":_vm.$_strings.importType.DRIVER,"uploadCsvProp":_vm.uploadCsv,"downloadTemplateCsvProp":_vm.downloadTemplateCsv,"dataTypeProp":"driver"},on:{"fetchList":_vm.fetchDriver}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"caption",attrs:{"hide-details":"","height":"5","items":_vm.statusItems,"placeholder":_vm.$_strings.driver.ALL_STATUS,"outlined":"","dense":""},on:{"change":function () {
              _vm.$router.replace({
                name: _vm.$route.name,
                query: Object.assign({}, _vm.$route.query,
                  {status: _vm.filterByStatus}),
              });
              _vm.setFilters()
            }},model:{value:(_vm.filterByStatus),callback:function ($$v) {_vm.filterByStatus=$$v},expression:"filterByStatus"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{ref:"search",staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.driver.SEARCH_DRIVER},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.profileImageUrl",fn:function(items){return [(items.item.profileImageUrl)?_c('v-img',{attrs:{"height":"80","width":"80","src":items.item.profileImageUrl,"contain":"","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_c('v-img',{attrs:{"height":"80","width":"80","src":require("@/assets/images/userProfile.png"),"contain":"","aspect-ratio":"1"}})]}},{key:"item.createdBy",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.createdBy))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.dateFormat(items.item.createdAt)))])]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"select-status-sopir",attrs:{"outlined":"","hide-details":"","dense":"","items":_vm.statusDriverItems},on:{"input":function (val) { return _vm.toggleUserActivation(item, val); },"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.isSuspended",fn:function(ref){
        var item = ref.item;
return [(item.isActive)?_c('v-select',{staticClass:"select-status-suspend",attrs:{"hide-details":"","outlined":"","dense":"","items":_vm.statusSuspend},on:{"input":function (val) { return _vm.toggleUserSuspend(item, val); },"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(item.isSuspended),callback:function ($$v) {_vm.$set(item, "isSuspended", $$v)},expression:"item.isSuspended"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.navigateToPageDriverEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.UPDATE))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.userAccess.canDelete)?{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteDriver(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.userAccess.canUpdate)?{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":[function () { return _vm.resetPassword(item.id); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-reset ")])]}}:null],null,true)},[_c('span',[_vm._v("Reset Password")])])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.driver.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }